import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {isFondKino, getBusinessDay} from 'app/selectors/AppSelectors';
import { MdKeyboardArrowDown } from "@react-icons/all-files/md/MdKeyboardArrowDown";
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import withScroll from 'app/hocs/withScroll';
import {withRouter} from 'react-router-dom';

let calendarOffsetTop = 0;
const ScrollButton = withScroll(KeydownEnterButton);

class MenuCalendarComponent extends PureComponent {
	static propTypes = {
		selectedDay: PropTypes.object.isRequired,
		updateSelectedDay: PropTypes.func.isRequired,
		seanceDates: PropTypes.arrayOf(PropTypes.string).isRequired,
		onClick: PropTypes.func.isRequired,
		isReleasePage: PropTypes.bool,
		isLoadedAdvertisements: PropTypes.bool.isRequired,
		isMobile: PropTypes.bool.isRequired,
		hasReleasesScrollButton: PropTypes.bool,
		isMonitor: PropTypes.bool.isRequired,
		history: PropTypes.object.isRequired,
		isPlanetarium: PropTypes.bool
	}

	state = {
		isShowMenu: false,
		isReleasePage: false,
		isMenuFixed: false,
		isActiveDay: true,
		isPlanetarium: false
	}

	componentDidMount = () => {
		calendarOffsetTop = this.calendarMenu.offsetTop;
		const activeDay = document.querySelector('.calendar__days-list > .calendar-mobile__date--active');

		if (calendarOffsetTop) {
			document.addEventListener('scroll', this.handleScrollDocument);
		}
		this.daysList.addEventListener('wheel', this.preventWheel);

		if (activeDay) {
			this.setState({
				isActiveDay: activeDay.dataset.index < this.getMenuLength()
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isLoadedAdvertisements !== this.props.isLoadedAdvertisements) {
			calendarOffsetTop = this.calendarMenu.offsetTop;
		}
	}

	componentWillUnmount = () => {
		document.removeEventListener('scroll', this.handleScrollDocument);
		this.daysList.removeEventListener('wheel', this.preventWheel);
	}

	getMenuLength = () => {
		const widthDate = 190; // ширина одной даты
		const container = document.querySelector('.container');

		if (container) {
			const padding = parseInt(getComputedStyle(container).paddingLeft.slice(0, 2), 10) * 2;
			const indent = this.props.isReleasePage ? 293 : 0; // 263 - poster, 30 - margin
			const calendarWidth = container.clientWidth - padding - indent;

			return parseInt(calendarWidth / widthDate, 10);
		}

		return 0;
	}

	handleScrollDocument = () => {
		const mainPage = document.querySelector('.page');
		const releasePage = document.querySelector('.release');
		const headerMenuHeight = document.getElementById('headerMenu').clientHeight || 0;

		if ((document.documentElement.scrollTop || document.body.scrollTop) >= (headerMenuHeight + calendarOffsetTop)) {
			this.setState({isMenuFixed: true});

			if (mainPage) {
				mainPage.classList.add('page--fixed-calendar');
			} else if (releasePage) {
				releasePage.classList.add('release--fixed-calendar');
			}
		} else {
			this.setState({isMenuFixed: false});

			if (mainPage) {
				mainPage.classList.remove('page--fixed-calendar');
			} else if (releasePage) {
				releasePage.classList.remove('release--fixed-calendar');
			}
		}
	}

	preventWheel = (event) => event.preventDefault();

	menuLinks = (isMobile) => {
		const {seanceDates, selectedDay} = this.props;

		const startDay = getBusinessDay();
		const endDay = moment(_.last(seanceDates)).format('YYYY-MM-DD');

		const rangeDates = Array.from((moment.range(startDay.format('YYYY-MM-DD'), endDay)).by('days'));

		if (rangeDates.length > 1 && this.daysList) setTimeout(this.handleCheckActiveDay, 100);

		return _.map(rangeDates, (momentDay, index) => {
			const formattedMomentDay = momentDay.format('YYYY-MM-DD');
			let title = isMobile
				? momentDay.format('dd D')
				: momentDay.format('dddd, D MMM');

			if (!isMobile) {
				if (index === 0) {
					title = i18n.t('Today');
				} else if (index === 1) {
					title = i18n.t('Tommorow');
				}
			}

			return {
				title,
				index,
				date: formattedMomentDay,
				holiday: momentDay.day() === 6 || momentDay.day() === 0,
				active: selectedDay.format('YYYY-MM-DD') === formattedMomentDay,
				disabled: seanceDates.indexOf(formattedMomentDay) === -1
			};
		});
	}

	handleCheckActiveDay = () => {
		const isActiveDay = !!document.querySelector(
			'.calendar__date--active:not(.calendar__date--select-day)',
		);

		const activeDayInList = this.daysList?.querySelector('.calendar-mobile__date--active');

		if (activeDayInList) {
			this.daysList.scrollLeft = activeDayInList.offsetLeft - parseInt(
				getComputedStyle(this.daysList).paddingLeft.slice(0, 2),
				10
			);
		}

		if (!this.state.isShowMenu) {
			this.setState({isActiveDay});
		}
	}

	handleClick = (event) => {
		const {history} = this.props;
		const {date, index, isButtonDisabled} = event.currentTarget.dataset;

		if (isButtonDisabled === 'true') {
			return;
		}

		const {updateSelectedDay, onClick} = this.props;

		this.setState({
			isShowMenu: false,
			isActiveDay: index < this.getMenuLength()
		});
		history.replace({search: `?date=${moment(date).format('YYYY-MM-DD')}`});
		onClick(date);
		updateSelectedDay(moment(date));
	}

	handleToggleMenu = () => {
		this.setState({
			isShowMenu: !this.state.isShowMenu
		});
	}

	handleMouseWheel = (event) => {
		if (this.daysList.scrollWidth > this.daysList.clientWidth) {
			event.preventDefault();
			const {currentTarget, deltaX, deltaY} = event;

			currentTarget.scrollLeft += deltaX || deltaY;
		}
	}

	calendarMobileDate = () =>
		_.map(this.menuLinks('mobile'), (day, index) => {
			const {
				disabled,
				date,
				holiday,
				active: currentDay,
				title: dayTitle,
				index: dayIndex
			} = day;

			const dateClass = classNames('calendar-mobile__date', {
				'calendar-mobile__date--active': currentDay,
				'calendar-mobile__date--disabled': disabled,
				'calendar-mobile__date--holiday': holiday
			});
			const dayWeek = dayTitle.split(' ')[0];
			const dayMonth = dayTitle.split(' ')[1];

			return (
				<KeydownEnterButton
					data-date={date}
					data-index={dayIndex}
					data-is-button-disabled={currentDay || disabled}
					className={dateClass}
					key={index}
					onClick={this.handleClick}
					tabIndex={this.state.isShowMenu ? 0 : -1}
				>
					<span className="calendar-mobile__date--day-week">{dayWeek}</span>
					<span className="calendar-mobile__date--day-month">{dayMonth}</span>
				</KeydownEnterButton>
			);
		})

	render() {
		const {selectedDay, isReleasePage, isMobile, hasReleasesScrollButton, isMonitor, isPlanetarium} = this.props;
		const {isShowMenu, isActiveDay, isMenuFixed} = this.state;
		const menuLinks = this.menuLinks();
		const menuLength = this.getMenuLength();
		const shouldAlterLinks = isFondKino && !isMonitor;
		const showReleasesScrollButton = shouldAlterLinks && hasReleasesScrollButton;
		const linksForRendering = shouldAlterLinks ? menuLinks.splice(0, 2) : menuLinks;
		const showSelectDayButton = shouldAlterLinks || (!isMobile && menuLinks.length > menuLength);

		const calendarClass = classNames('calendar calendar-container', {
			'calendar--release-page': isReleasePage,
			'calendar--open': isShowMenu
		});
		const calendarMenuClass = classNames('calendar-menu', {
			'calendar-menu--open': isShowMenu,
			'calendar-menu--fixed': isMenuFixed
		});
		const selectDayClass = classNames('calendar__date calendar__date--select-day', {
			'calendar__date--active': isShowMenu || !isActiveDay
		});

		return (
			<div
				className={calendarMenuClass}
				ref={(calendarMenu) => { this.calendarMenu = calendarMenu; }}
			>
				{(isMobile || menuLength > 0) &&
					<div className={calendarClass}>
						<div style={{display: 'flex'}}>
							{_.map(linksForRendering, (day, index) => {
								const {
									disabled,
									date,
									active: currentDay,
									title: dayTitle,
									index: dayIndex
								} = day;

								if (index < menuLength || isMobile) {
									const dateClass = classNames('calendar__date', {
										'calendar__date--active': currentDay && !isShowMenu,
										'calendar__date--disabled': disabled
									});
									const dayWeek = dayTitle.split(',')[0];
									const dayMonth = dayTitle.split(',')[1];

									return (
										<KeydownEnterButton
											data-date={date}
											data-index={dayIndex}
											data-is-button-disabled={currentDay || disabled}
											className={dateClass}
											key={index}
											onClick={this.handleClick}
										>
											{dayWeek}{dayMonth ? `, ${dayMonth}` : ''}
										</KeydownEnterButton>
									);
								}

								return null;
							})}
						</div>
						{showSelectDayButton &&
							<KeydownEnterButton
								className={selectDayClass}
								onClick={this.handleToggleMenu}
							>
								{isActiveDay ? i18n.t('SelectDay') : selectedDay.format('dddd, D MMM')}
								<span className="calendar__arrow">
									<MdKeyboardArrowDown size={24} />
								</span>
							</KeydownEnterButton>
						}
						{!isPlanetarium && showReleasesScrollButton && (
							<ScrollButton className="calendar__date" targetElementId="releasesSoon">
								{i18n.t('SoonAtCinemas')}
							</ScrollButton>
						)}
					</div>
				}
				<div
					className="container calendar__days-list"
					onWheel={this.handleMouseWheel}
					ref={(daysList) => { this.daysList = daysList; }}
				>
					{this.calendarMobileDate()}
					<span className="calendar__empty" />
				</div>
			</div>
		);
	}
}

export default withRouter(MenuCalendarComponent);
