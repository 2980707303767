import React, { Suspense } from 'react';
import { SHARED_METRICS_ID } from 'app/constants/SettingsConstants';
import Spinner from 'app/components/SpinnerComponent';
import {
	MONITOR_CITY_CINEMA_ID,
	MONITOR_KINOSTAR_CINEMA_ID, MONITOR_SBS_CINEMA_ID,
} from 'app/constants/CinemaConstants';
import { Cinema, CustomPage } from 'app/types/common';
import { __DEV__ } from 'app/constants/AppConstants';

export const convertToRubles = sum => (sum ? sum / 100 : 0);
export const getTitles = arrayOfObjects => _.pluck(arrayOfObjects, 'title').join(', ');

export const normalizeResponseData = <T extends {name: string, value: unknown}>(arrayOfObjects: T[]) =>
	arrayOfObjects.reduce((memo, object) => {
		memo[object.name] = object.value;

		return memo;
}, {} as {
	[name: string]: T["value"]
});

export const setYaReachGoal = (
	goalId: string,
	params: object | null = null,
	isPublicEvent: boolean = true,
	recursiveCallsCount = 1
) => {
	if (__DEV__ || recursiveCallsCount > 15) {
		window[`yaCounter${SHARED_METRICS_ID}`]?.reachGoal(goalId, params);

		return;
	}

	let counterIDs: Array<number | string> = [];

	if (isPublicEvent && window.currentYandexCounters ) {
		counterIDs = Array.from(window.currentYandexCounters)
	};

	counterIDs.push(SHARED_METRICS_ID);

	if (counterIDs.some((id) => !window[`yaCounter${id}`])) {
		setTimeout(() => setYaReachGoal(goalId, params, isPublicEvent, recursiveCallsCount + 1), 500);
	}

	counterIDs.forEach(id => {
		window[`yaCounter${id}`]?.reachGoal(goalId, params);
	});
};

export const loadComponent = <T extends React.ElementType>(Component: T) => (props: React.ComponentProps<T>) => (
	<Suspense fallback={<Spinner />}>
		<Component {...props} />
	</Suspense>
);

export const scrollToTop = () => {
	const rect = document.getElementsByClassName('menu--navigation')[0].getBoundingClientRect();

	if (rect.y < 0) {
		document.getElementsByClassName('header__container')[0].scrollIntoView();
	}
};

export const sortMonitorCinemas = <T extends {id: number}>(cinemas: T[]): T[] =>
	[...cinemas].sort((first, second) => {
		if (first.id === MONITOR_SBS_CINEMA_ID || second.id === MONITOR_CITY_CINEMA_ID) return -1;
		if (first.id === MONITOR_CITY_CINEMA_ID || second.id === MONITOR_SBS_CINEMA_ID) return 1;

		return 0;
	},
);

export const parsePhone = (phoneString: string) => phoneString.match(/^\+?\d|\d+/g)?.join('') ?? '';

export const getIsWidgetDisabledInSpecificCinema = (cinemaId) => [MONITOR_KINOSTAR_CINEMA_ID].includes(cinemaId);

export const transformMenuLinks: (links: CustomPage[], prefix: number | string) => CustomPage[] = (links, prefix) => links.map(link => ({
	...link,
	href: `/${prefix}${link.href}`,
	sub: link.sub && transformMenuLinks(link.sub, prefix),
}));

export const getDateFromSearch = (searchString: string) => {
	const parameters = _.object<{[key: string]: string}>(searchString.slice(1).split('&').map(item => item.split('=')));

	if (parameters.date) {
		const pattern = parameters.date.match(/\d{4}-\d{2}-\d{2}/);

		return pattern ? pattern[0] : null;
	}

	return null;
};

export const calcVideoFrameSize = (figureNode: HTMLElement) => {
	const iframe = figureNode.querySelector<HTMLIFrameElement>('iframe');

	if (!iframe || !figureNode.style.width.includes("px") || !figureNode.style.height.includes("px")) return;

	const initialWidth = parseInt(figureNode.style.width);
	const initialHeight = parseInt(figureNode.style.height);

	if (initialWidth && initialHeight && figureNode.offsetWidth < initialWidth) {
		const newHeight = initialHeight / initialWidth * figureNode.offsetWidth;

		figureNode.style.height = `${newHeight}px`;
		iframe.style.height = `${newHeight}px`;
	}
}

// берем общие настройки для всех кинотеатров в гододе
// так как могут быть кейсы когда в одном кинотеатре включениы настройки, а в дрогом нет
export const getDisplaySettingsOfCinemasForCurrentCity = (cinemas: Cinema[], currentCityId: number) => {
	const settingsCinemasOfCurrentCity = cinemas.reduce((acc, cinema) => {
		if (cinema.city_id === currentCityId) {
			const sectionDisplaySettings = acc.section_display_settings;
			const currentSectionDisplaySettings = cinema.display_settings.section_display_settings;

			acc = {
				...acc,
				section_display_settings: {
					imax: sectionDisplaySettings.imax || currentSectionDisplaySettings.imax,
					vip: sectionDisplaySettings.vip || currentSectionDisplaySettings.vip,
					pro_culture: sectionDisplaySettings.vip || currentSectionDisplaySettings.vip,
					not_movie: sectionDisplaySettings.not_movie || currentSectionDisplaySettings.not_movie,
				}
			};
		}

		return acc;
	}, cinemas[0].display_settings);

	return settingsCinemasOfCurrentCity;
}
